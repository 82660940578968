<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="documentWrap preview">
    <i class="iconfont icon-document"></i>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.documentWrap {
  width: 100%;
  height: 100%;
  user-select: none;
  display: table;
  i {
    font-size: inherit;
  }
}
</style>
